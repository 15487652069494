import { Container, Row, Col, Card } from 'react-bootstrap';
import { BiPhone, BiGlobe } from 'react-icons/bi';

function Footer() {
  return (
    <footer className='bg-dark text-white py-5 mt-auto'>
      <Container>
        {/* Developer CTA Section */}
        {/* <Row className='mb-5'>
          <Col lg={12}>
            <Card className='border-0 bg-gradient text-white shadow-lg overflow-hidden'>
              <Card.Body
                className='p-4'
                style={{
                  background: 'linear-gradient(to right, #000, #1a1a2e)',
                }}
              >
                <Row className='align-items-center'>
                  <Col lg={8} md={7}>
                    <h4 className='fw-bold mb-2'>
                      Need a terrific web application for your business?
                    </h4>
                    <p className='mb-0'>
                      Get a custom-built, professional web application that
                      transforms your business. Contact Ayabonga Qwabi for
                      expert web development services.
                    </p>
                  </Col>
                  <Col lg={4} md={5} className='text-md-end mt-3 mt-md-0'>
                    <div className='d-flex flex-column flex-sm-row gap-2 justify-content-md-end'>
                      <a
                        href='tel:+27603116777'
                        className='btn btn-outline-light d-flex align-items-center justify-content-center gap-2'
                      >
                        <BiPhone /> 060 311 6777
                      </a>
                      <a
                        href='https://ayabonga.com'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='btn btn-danger d-flex align-items-center justify-content-center gap-2'
                      >
                        <BiGlobe /> Visit Website
                      </a>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}

        <Row>
          <Col lg={4} className='mb-4 mb-lg-0'>
            <img
              src='/cp-logo-full-white.svg'
              alt='ClinicPlus Logo'
              width='180'
              height='60'
              className='mb-3'
            />
            <p className='text-white mb-3'>
              Supporting healthy employees and healthy businesses since 2007.
              Your trusted partner in occupational health services.
            </p>
            <div className='d-flex gap-3 mb-3'>
              <a href='#' className='text-white fs-5'>
                <i className='bi bi-facebook'></i>
              </a>
              <a href='#' className='text-white fs-5'>
                <i className='bi bi-twitter'></i>
              </a>
              <a href='#' className='text-white fs-5'>
                <i className='bi bi-linkedin'></i>
              </a>
              <a href='#' className='text-white fs-5'>
                <i className='bi bi-instagram'></i>
              </a>
            </div>
          </Col>
          <Col lg={2} md={6} className='mb-4 mb-lg-0'>
            <h5 className='fw-bold mb-3'>Quick Links</h5>
            <ul className='list-unstyled'>
              <li className='mb-2'>
                <a
                  href='/'
                  className='text-white text-decoration-none hover-text-white'
                >
                  Home
                </a>
              </li>
              <li className='mb-2'>
                <a
                  href='/services'
                  className='text-white text-decoration-none hover-text-white'
                >
                  Services
                </a>
              </li>
              <li className='mb-2'>
                <a
                  href='/about'
                  className='text-white text-decoration-none hover-text-white'
                >
                  About Us
                </a>
              </li>
              <li className='mb-2'>
                <a
                  href='/register'
                  className='text-white text-decoration-none hover-text-white'
                >
                  Register
                </a>
              </li>
              <li>
                <a
                  href='/login'
                  className='text-white text-decoration-none hover-text-white'
                >
                  Login
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={3} md={6} className='mb-4 mb-lg-0'>
            <h5 className='fw-bold mb-3'>Contact Us</h5>
            <p className='text-white mb-1'>
              <strong>Address:</strong>
              <br />
              2 Churchill Street
              <br />
              Witbank, Emalahleni
              <br />
              South Africa, 1035
            </p>
            <p className='text-white mb-1'>
              <strong>Phone:</strong>
              <br />
              <a href='tel:+27136560044'>+27 13 656 0044</a> /{' '}
              <a href='tel:+27136562020'>+27 13 656 2020</a>
            </p>
            <p className='text-white'>
              <strong>Email:</strong>
              <br />
              bookings@clinicpluswtb.co.za
            </p>
          </Col>
          <Col lg={3} md={6}>
            <h5 className='fw-bold mb-3'>Business Hours</h5>
            <p className='text-white mb-1'>
              <strong>Monday - Friday:</strong>
              <br />
              8:00 AM - 5:00 PM
            </p>
            <p className='text-white mb-1'>
              <strong>Saturday:</strong>
              <br />
              9:00 AM - 1:00 PM
            </p>
            <p className='text-white'>
              <strong>Sunday:</strong>
              <br />
              Closed
            </p>
          </Col>
        </Row>
        <hr className='my-4 bg-secondary' />
        <Row>
          <Col>
            <p className='text-center text-white mb-0'>
              © {new Date().getFullYear()} ClinicPlus. All rights reserved. |{' '}
              <a
                href='/privacy'
                className='text-white text-decoration-none hover-text-white'
              >
                Privacy Policy
              </a>{' '}
              |{' '}
              <a
                href='/terms'
                className='text-white text-decoration-none hover-text-white'
              >
                Terms of Service
              </a>
            </p>
          </Col>
        </Row>
        {/* <Row className='mt-3'>
          <Col>
            <p className='text-center text-white mb-0'>
              Built by{' '}
              <a
                href='https://ayabonga.com'
                className='text-danger text-decoration-none fw-bold'
                target='_blank'
                rel='noopener noreferrer'
              >
                Ayabonga Qwabi
              </a>
            </p>
          </Col>
        </Row> */}
      </Container>
    </footer>
  );
}

export default Footer;
