'use client';

import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Badge,
  Tab,
  Nav,
  Accordion,
} from 'react-bootstrap';
import {
  BiFirstAid,
  BiDumbbell,
  BiShield,
  BiBookReader,
  BiCheckCircle,
  BiGroup,
  BiCalendarCheck,
  BiLineChart,
  BiPulse,
  BiTestTube,
  BiClipboard,
  BiMicrophone,
  BiLaptop,
  BiPhone,
  BiEnvelope,
} from 'react-icons/bi';
import NavigationBar from '../components/newnav';
import Footer from '../components/newfooter';

function ServicesPage() {
  const [activeTab, setActiveTab] = useState('medical');

  return (
    <div className='d-flex flex-column min-vh-100 main-about'>
      <NavigationBar activePage='services' />

      {/* Add padding to the top of the page to account for the fixed navbar */}
      <div style={{ paddingTop: '56px' }}></div>

      {/* Hero Section */}
      <div className='position-relative text-white mb-5'>
        <div className='position-absolute top-0 start-0 w-100 h-100'>
          <img
            src='https://images.unsplash.com/photo-1631815588090-d4bfec5b1ccb?q=80&w=2940&auto=format&fit=crop'
            alt='Medical equipment and healthcare professionals'
            className='w-100 h-100 object-fit-cover'
            style={{ filter: 'brightness(0.4)' }}
          />
        </div>

        <Container className='position-relative py-5'>
          <Row className='py-5 justify-content-center text-center'>
            <Col lg={8} className='py-lg-5'>
              <h1 className='display-4 fw-bold mb-4'>Our Services</h1>
              <p className='lead fs-4 mb-0'>
                Comprehensive occupational health solutions tailored to your
                business needs
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Introduction Section */}
      <Container className='mb-5'>
        <Row className='justify-content-center text-center'>
          <Col lg={8}>
            <h2 className='display-6 fw-bold mb-4'>
              Occupational Health Excellence
            </h2>
            <p className='lead'>
              At ClinicPlus, we offer a comprehensive range of occupational
              health services designed to promote workplace wellness, ensure
              compliance with health and safety regulations, and boost overall
              productivity. Our team of experienced healthcare professionals is
              dedicated to providing tailored solutions that meet the unique
              needs of your business.
            </p>
          </Col>
        </Row>
      </Container>

      {/* Services Tabs Section */}
      <Container className='mb-5'>
        <Tab.Container
          id='services-tabs'
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
        >
          <Row className='mb-4'>
            <Col lg={12}>
              <Nav
                variant='pills'
                className='service-nav flex-column flex-md-row justify-content-center'
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey='medical'
                    className='text-center px-4 py-3 m-1'
                  >
                    <BiFirstAid className='d-block mx-auto mb-2' size={24} />
                    Medical Assessments
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='wellness'
                    className='text-center px-4 py-3 m-1'
                  >
                    <BiDumbbell className='d-block mx-auto mb-2' size={24} />
                    Wellness Programs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='safety'
                    className='text-center px-4 py-3 m-1'
                  >
                    <BiShield className='d-block mx-auto mb-2' size={24} />
                    Health & Safety
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='training'
                    className='text-center px-4 py-3 m-1'
                  >
                    <BiBookReader className='d-block mx-auto mb-2' size={24} />
                    Training & Workshops
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Tab.Content>
                {/* Medical Assessments Tab */}
                <Tab.Pane eventKey='medical'>
                  <Row className='align-items-center mb-5'>
                    <Col lg={6} className='mb-4 mb-lg-0'>
                      <h3 className='h2 fw-bold mb-4'>Medical Assessments</h3>
                      <p className='lead mb-4'>
                        Our comprehensive medical assessment services ensure
                        your workforce is fit for duty and help identify
                        potential health risks before they become serious
                        issues.
                      </p>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Pre-employment medical examinations
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>Periodic health assessments</p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>Fitness-to-work evaluations</p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Specialized assessments for high-risk roles
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-4'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Return-to-work assessments after illness or injury
                        </p>
                      </div>
                      <Button variant='danger' className='px-4 py-2'>
                        Learn More
                      </Button>
                    </Col>
                    <Col lg={6}>
                      <img
                        src='https://images.unsplash.com/photo-1581595219315-a187dd40c322?q=80&w=2874&auto=format&fit=crop'
                        alt='Medical assessment'
                        className='img-fluid rounded-3 shadow-lg'
                      />
                    </Col>
                  </Row>

                  <Row className='mt-5'>
                    <Col lg={12}>
                      <h4 className='fw-bold mb-4'>
                        Our Medical Assessment Process
                      </h4>
                    </Col>
                    <Col md={6} lg={3} className='mb-4'>
                      <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4 text-center'>
                          <div
                            className='rounded-circle bg-danger text-white d-flex align-items-center justify-content-center mx-auto mb-3'
                            style={{ width: '60px', height: '60px' }}
                          >
                            <BiClipboard size={30} />
                          </div>
                          <h5 className='fw-bold mb-3'>
                            1. Initial Consultation
                          </h5>
                          <p className='mb-0'>
                            We discuss your company's specific needs and
                            requirements to tailor our assessments.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6} lg={3} className='mb-4'>
                      <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4 text-center'>
                          <div
                            className='rounded-circle bg-danger text-white d-flex align-items-center justify-content-center mx-auto mb-3'
                            style={{ width: '60px', height: '60px' }}
                          >
                            <BiCalendarCheck size={30} />
                          </div>
                          <h5 className='fw-bold mb-3'>2. Scheduling</h5>
                          <p className='mb-0'>
                            We coordinate convenient assessment times for your
                            employees with minimal disruption.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6} lg={3} className='mb-4'>
                      <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4 text-center'>
                          <div
                            className='rounded-circle bg-danger text-white d-flex align-items-center justify-content-center mx-auto mb-3'
                            style={{ width: '60px', height: '60px' }}
                          >
                            <BiTestTube size={30} />
                          </div>
                          <h5 className='fw-bold mb-3'>3. Assessment</h5>
                          <p className='mb-0'>
                            Our medical professionals conduct thorough
                            examinations following industry standards.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6} lg={3} className='mb-4'>
                      <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4 text-center'>
                          <div
                            className='rounded-circle bg-danger text-white d-flex align-items-center justify-content-center mx-auto mb-3'
                            style={{ width: '60px', height: '60px' }}
                          >
                            <BiLineChart size={30} />
                          </div>
                          <h5 className='fw-bold mb-3'>
                            4. Results & Follow-up
                          </h5>
                          <p className='mb-0'>
                            We provide detailed reports and recommendations for
                            any necessary follow-up actions.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab.Pane>

                {/* Wellness Programs Tab */}
                <Tab.Pane eventKey='wellness'>
                  <Row className='align-items-center mb-5'>
                    <Col lg={6} className='order-lg-2 mb-4 mb-lg-0'>
                      <h3 className='h2 fw-bold mb-4'>Wellness Programs</h3>
                      <p className='lead mb-4'>
                        Our wellness initiatives are designed to educate,
                        motivate, and empower employees to make positive
                        lifestyle choices that enhance their overall wellbeing
                        and productivity.
                      </p>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Health risk assessments and screenings
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Stress management and mental health programs
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Nutrition and physical activity initiatives
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Smoking cessation and substance abuse programs
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-4'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Chronic disease management support
                        </p>
                      </div>
                      <Button variant='danger' className='px-4 py-2'>
                        Learn More
                      </Button>
                    </Col>
                    <Col lg={6} className='order-lg-1'>
                      <img
                        src='https://images.unsplash.com/photo-1571019613576-2b22c76fd955?q=80&w=2940&auto=format&fit=crop'
                        alt='Wellness program'
                        className='img-fluid rounded-3 shadow-lg'
                      />
                    </Col>
                  </Row>

                  <Row className='bg-light py-4 px-2 rounded-3 mt-5'>
                    <Col lg={12} className='mb-4'>
                      <h4 className='fw-bold'>
                        Benefits of Our Wellness Programs
                      </h4>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <div className='d-flex'>
                        <div className='flex-shrink-0'>
                          <div className='rounded-circle bg-danger p-3 text-white'>
                            <BiPulse size={24} />
                          </div>
                        </div>
                        <div className='ms-3'>
                          <h5 className='fw-bold'>Improved Employee Health</h5>
                          <p>
                            Reduce sick days and healthcare costs through
                            preventive health measures and early intervention.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <div className='d-flex'>
                        <div className='flex-shrink-0'>
                          <div className='rounded-circle bg-danger p-3 text-white'>
                            <BiGroup size={24} />
                          </div>
                        </div>
                        <div className='ms-3'>
                          <h5 className='fw-bold'>Enhanced Morale</h5>
                          <p>
                            Boost employee satisfaction, engagement, and
                            retention through wellness initiatives that show you
                            care.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <div className='d-flex'>
                        <div className='flex-shrink-0'>
                          <div className='rounded-circle bg-danger p-3 text-white'>
                            <BiLineChart size={24} />
                          </div>
                        </div>
                        <div className='ms-3'>
                          <h5 className='fw-bold'>Increased Productivity</h5>
                          <p>
                            Healthier employees are more productive, focused,
                            and contribute to a positive work environment.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>

                {/* Health & Safety Tab */}
                <Tab.Pane eventKey='safety'>
                  <Row className='align-items-center mb-5'>
                    <Col lg={6} className='mb-4 mb-lg-0'>
                      <h3 className='h2 fw-bold mb-4'>
                        Health & Safety Consultations
                      </h3>
                      <p className='lead mb-4'>
                        Our expert consultations help you create safe and
                        compliant work environments, reduce workplace risks, and
                        improve overall occupational health standards.
                      </p>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Workplace health and safety audits
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Risk assessments and hazard identification
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Compliance with health and safety regulations
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>Emergency response planning</p>
                      </div>
                      <div className='d-flex align-items-center mb-4'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Ergonomic assessments and recommendations
                        </p>
                      </div>
                      <Button variant='danger' className='px-4 py-2'>
                        Learn More
                      </Button>
                    </Col>
                    <Col lg={6}>
                      <img
                        src='https://images.unsplash.com/photo-1581092921461-7031e8fbc93e?q=80&w=2940&auto=format&fit=crop'
                        alt='Health and safety consultation'
                        className='img-fluid rounded-3 shadow-lg'
                      />
                    </Col>
                  </Row>

                  <Row className='mt-5'>
                    <Col lg={12} className='mb-4'>
                      <h4 className='fw-bold'>Industries We Serve</h4>
                      <p>
                        Our health and safety consultations are tailored to the
                        specific needs of various industries:
                      </p>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4'>
                          <h5 className='fw-bold mb-3'>Mining</h5>
                          <p>
                            Specialized safety protocols for underground and
                            surface mining operations, including dust control,
                            ventilation assessment, and emergency response
                            planning.
                          </p>
                          <Badge bg='danger' className='mt-2'>
                            High-Risk Environment
                          </Badge>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4'>
                          <h5 className='fw-bold mb-3'>Construction</h5>
                          <p>
                            Comprehensive safety assessments for construction
                            sites, including fall protection, equipment safety,
                            and hazardous material handling procedures.
                          </p>
                          <Badge bg='danger' className='mt-2'>
                            Safety Critical
                          </Badge>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4'>
                          <h5 className='fw-bold mb-3'>Manufacturing</h5>
                          <p>
                            Customized safety protocols for production
                            facilities, including machine guarding, chemical
                            safety, and ergonomic workstation design.
                          </p>
                          <Badge bg='danger' className='mt-2'>
                            Process Oriented
                          </Badge>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab.Pane>

                {/* Training & Workshops Tab */}
                <Tab.Pane eventKey='training'>
                  <Row className='align-items-center mb-5'>
                    <Col lg={6} className='order-lg-2 mb-4 mb-lg-0'>
                      <h3 className='h2 fw-bold mb-4'>Training & Workshops</h3>
                      <p className='lead mb-4'>
                        Through interactive workshops and training sessions, we
                        equip both employees and management with the knowledge
                        and skills needed to maintain a healthy workplace.
                      </p>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          First aid and emergency response training
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Mental health awareness and support
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>Ergonomics and injury prevention</p>
                      </div>
                      <div className='d-flex align-items-center mb-3'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Health and safety compliance training
                        </p>
                      </div>
                      <div className='d-flex align-items-center mb-4'>
                        <BiCheckCircle className='text-danger me-2' size={24} />
                        <p className='mb-0'>
                          Wellness champion development programs
                        </p>
                      </div>
                      <Button variant='danger' className='px-4 py-2'>
                        Learn More
                      </Button>
                    </Col>
                    <Col lg={6} className='order-lg-1'>
                      <img
                        src='https://images.unsplash.com/photo-1552581234-26160f608093?q=80&w=2940&auto=format&fit=crop'
                        alt='Training workshop'
                        className='img-fluid rounded-3 shadow-lg'
                      />
                    </Col>
                  </Row>

                  <Row className='mt-5'>
                    <Col lg={12} className='mb-4'>
                      <h4 className='fw-bold'>Training Delivery Methods</h4>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <Card className='text-center h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4'>
                          <div
                            className='bg-danger text-white rounded-circle mx-auto mb-3 d-flex align-items-center justify-content-center'
                            style={{ width: '70px', height: '70px' }}
                          >
                            <BiGroup size={35} />
                          </div>
                          <h5 className='fw-bold mb-3'>On-site Workshops</h5>
                          <p>
                            Hands-on training conducted at your workplace,
                            tailored to your specific environment and needs.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <Card className='text-center h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4'>
                          <div
                            className='bg-danger text-white rounded-circle mx-auto mb-3 d-flex align-items-center justify-content-center'
                            style={{ width: '70px', height: '70px' }}
                          >
                            <BiMicrophone size={35} />
                          </div>
                          <h5 className='fw-bold mb-3'>Seminars</h5>
                          <p>
                            Engaging presentations and discussions led by
                            industry experts on key occupational health topics.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6} lg={4} className='mb-4'>
                      <Card className='text-center h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                        <Card.Body className='p-4'>
                          <div
                            className='bg-danger text-white rounded-circle mx-auto mb-3 d-flex align-items-center justify-content-center'
                            style={{ width: '70px', height: '70px' }}
                          >
                            <BiLaptop size={35} />
                          </div>
                          <h5 className='fw-bold mb-3'>Online Learning</h5>
                          <p>
                            Flexible e-learning modules that allow employees to
                            complete training at their own pace.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>

      {/* Packages Section */}
      {/* <Container className='mb-5 py-5 bg-light rounded-3'>
        <Row className='justify-content-center text-center mb-5'>
          <Col lg={8}>
            <h2 className='display-6 fw-bold mb-3'>Service Packages</h2>
            <p className='lead'>
              Choose from our range of comprehensive service packages or contact
              us for a customized solution tailored to your specific needs.
            </p>
          </Col>
        </Row>

        <Row className='g-4 justify-content-center'>
          <Col md={6} lg={4}>
            <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <div className='bg-dark text-white p-3 text-center'>
                <h4 className='mb-0'>Essential Package</h4>
              </div>
              <Card.Body className='p-4'>
                <div className='text-center mb-4'>
                  <h5 className='text-white mb-3'>Starting at</h5>
                  <h3 className='display-5 fw-bold text-danger'>R15,000</h3>
                  <p className='text-white'>per month</p>
                </div>
                <ul className='list-unstyled mb-4'>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Basic pre-employment assessments</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Quarterly health and safety audits</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Annual wellness day</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Basic health and safety training</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Monthly reporting</span>
                  </li>
                </ul>
                <div className='text-center'>
                  <Button variant='outline-danger' className='px-4 py-2 w-100'>
                    Select Package
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card
              className='h-100 border-0 shadow-lg hover-shadow-lg transition-all'
              style={{ transform: 'scale(1.05)' }}
            >
              <div className='bg-danger text-white p-3 text-center'>
                <h4 className='mb-0'>Premium Package</h4>
                <Badge
                  bg='light'
                  text='danger'
                  className='position-absolute top-0 end-0 mt-2 me-2'
                >
                  Most Popular
                </Badge>
              </div>
              <Card.Body className='p-4'>
                <div className='text-center mb-4'>
                  <h5 className='text-white mb-3'>Starting at</h5>
                  <h3 className='display-5 fw-bold text-danger'>R25,000</h3>
                  <p className='text-white'>per month</p>
                </div>
                <ul className='list-unstyled mb-4'>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Comprehensive medical assessments</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Monthly health and safety audits</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Quarterly wellness programs</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Advanced safety training workshops</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Dedicated account manager</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Weekly reporting and analytics</span>
                  </li>
                </ul>
                <div className='text-center'>
                  <Button variant='danger' className='px-4 py-2 w-100'>
                    Select Package
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <div className='bg-dark text-white p-3 text-center'>
                <h4 className='mb-0'>Enterprise Package</h4>
              </div>
              <Card.Body className='p-4'>
                <div className='text-center mb-4'>
                  <h5 className='text-white mb-3'>Starting at</h5>
                  <h3 className='display-5 fw-bold text-danger'>R40,000</h3>
                  <p className='text-white'>per month</p>
                </div>
                <ul className='list-unstyled mb-4'>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>All Premium package features</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>On-site medical professional</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Custom wellness program development</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>24/7 emergency medical support</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Executive health assessments</span>
                  </li>
                  <li className='d-flex align-items-center mb-3'>
                    <BiCheckCircle className='text-danger me-2' size={20} />
                    <span>Comprehensive data analytics</span>
                  </li>
                </ul>
                <div className='text-center'>
                  <Button variant='outline-danger' className='px-4 py-2 w-100'>
                    Select Package
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}

      {/* FAQ Section */}
      <Container className='mb-5'>
        <Row className='justify-content-center text-center mb-5'>
          <Col lg={8}>
            <h2 className='display-6 fw-bold mb-3'>
              Frequently Asked Questions
            </h2>
            <p className='lead'>
              Find answers to common questions about our occupational health
              services
            </p>
          </Col>
        </Row>

        <Row className='justify-content-center'>
          <Col lg={10}>
            <Accordion defaultActiveKey='0' className='shadow-sm'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>
                  How often should employees undergo medical assessments?
                </Accordion.Header>
                <Accordion.Body>
                  The frequency of medical assessments depends on various
                  factors including industry regulations, job roles, and
                  exposure to hazards. Generally, we recommend annual
                  assessments for most employees, with more frequent assessments
                  (every 6 months) for those in high-risk positions or with
                  pre-existing health conditions. Our team can help you
                  determine the optimal frequency based on your specific
                  workplace requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>
                  Can you customize wellness programs for our specific workplace
                  needs?
                </Accordion.Header>
                <Accordion.Body>
                  We specialize in creating customized wellness programs that
                  address the unique health challenges and goals of your
                  organization. Our process begins with a comprehensive
                  workplace assessment to identify specific needs, followed by
                  the development of targeted initiatives. We work closely with
                  your management team to ensure the program aligns with your
                  company culture and objectives.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>
                  How do your services help with regulatory compliance?
                </Accordion.Header>
                <Accordion.Body>
                  Our team stays up-to-date with all relevant occupational
                  health and safety regulations in South Africa. We conduct
                  thorough audits to identify any compliance gaps, provide
                  detailed recommendations for addressing these issues, and help
                  implement necessary changes. Our documentation and reporting
                  systems are designed to meet regulatory requirements, making
                  it easier for you to demonstrate compliance during
                  inspections.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='3'>
                <Accordion.Header>
                  Do you offer on-site services or do employees need to visit
                  your clinic?
                </Accordion.Header>
                <Accordion.Body>
                  We offer both options for your convenience. Our mobile clinic
                  can bring services directly to your workplace, minimizing
                  disruption to your operations. Alternatively, employees can
                  visit our fully-equipped clinic in Witbank. The choice depends
                  on your company size, location, and specific service needs.
                  Many clients opt for a combination of both approaches.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='4'>
                <Accordion.Header>
                  What ROI can we expect from implementing your occupational
                  health services?
                </Accordion.Header>
                <Accordion.Body>
                  Clients typically see returns in several areas: reduced
                  absenteeism (average 20-30% reduction), lower healthcare
                  costs, improved productivity, and decreased workers'
                  compensation claims. While specific ROI varies by industry and
                  implementation level, our comprehensive reporting helps track
                  these improvements. Most clients begin seeing measurable
                  results within 6-12 months of program implementation.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>

      {/* Call to Action Section */}
      <Container className='mb-5'>
        <Row>
          <Col lg={12}>
            <Card className='border-0 bg-gradient text-white shadow-lg overflow-hidden'>
              <Card.Body
                className='p-5'
                style={{
                  background: 'linear-gradient(to right, #dc3545, #9a0000)',
                }}
              >
                <Row className='align-items-center'>
                  <Col lg={8}>
                    <h2 className='display-6 fw-bold mb-3'>
                      Ready to Enhance Workplace Health?
                    </h2>
                    <p className='lead mb-4'>
                      Contact us today to discuss how our occupational health
                      services can benefit your organization.
                    </p>
                    <div className='d-flex flex-column flex-md-row gap-4 mb-4'>
                      <div className='d-flex align-items-center'>
                        <div className='bg-white text-danger rounded-circle p-2 me-3'>
                          <BiPhone size={24} />
                        </div>
                        <div>
                          <p className='mb-0 fw-bold'>Phone</p>
                          <a
                            href='tel:+27136562020'
                            className='text-white text-decoration-none'
                          >
                            +27 13 656 2020
                          </a>
                        </div>
                      </div>
                      <div className='d-flex align-items-center'>
                        <div className='bg-white text-danger rounded-circle p-2 me-3'>
                          <BiEnvelope size={24} />
                        </div>
                        <div>
                          <p className='mb-0 fw-bold'>Email</p>
                          <a
                            href='mailto:bookings@clinicpluswtb.co.za'
                            className='text-white text-decoration-none'
                          >
                            bookings@clinicpluswtb.co.za
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} className='text-center text-lg-end'>
                    <a href='/register'>
                      <Button
                        variant='light'
                        size='lg'
                        className='px-4 py-3 text-danger fw-bold'
                      >
                        <BiCalendarCheck className='me-2' size={20} />
                        Schedule a Consultation
                      </Button>
                    </a>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default ServicesPage;
