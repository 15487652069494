'use client';

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';
import {
  BiUser,
  BiEnvelope,
  BiLock,
  BiPhone,
  BiArrowBack,
  BiInfoCircle,
  BiCheckCircle,
  BiShield,
  BiIdCard,
} from 'react-icons/bi';
import NavigationBar from '../../components/newnav';
import Footer from '../../components/newfooter';

function RegisterPage({ socket }) {
  const navigate = useNavigate();

  // Single state object for form data
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    cell: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  // Single state for general error message
  const [generalError, setGeneralError] = useState('');

  // Loading state
  const [loading, setLoading] = useState(false);

  // Password visibility toggles
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Handle input change - extremely simplified
  function handleChange(e) {
    const { name, value } = e.target;

    // Special handling for cell number to only allow digits
    if (name === 'cell') {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData((prev) => ({ ...prev, [name]: numericValue }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  }

  // Handle form submission
  function handleSubmit(e) {
    e.preventDefault();

    // Basic validation on submit only
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      // Show the first error
      setGeneralError(Object.values(errors)[0]);
      return;
    }

    setGeneralError('');
    setLoading(true);

    // Check if user exists
    socket.emit('GET_USER_BY_EMAIL', { email: formData.email });

    socket.on('RECEIVE_USER', (user) => {
      if (user) {
        setGeneralError('User with this email already exists');
        setLoading(false);
        socket.off('RECEIVE_USER');
      } else {
        socket.off('RECEIVE_USER');

        // Save new user
        socket.emit('SAVE_NEW_USER', {
          details: {
            name: formData.name,
            surname: formData.surname,
            email: formData.email,
            cell: formData.cell,
          },
          appointmentsCanEdit: [],
          appointmentsManaging: [],
          companiesCanEdit: [],
          companiesManaging: [],
          isSuspended: false,
          role: 'client',
          password: formData.password,
        });

        socket.on('RECEIVE_SAVE_USER_SUCCESS', (data) => {
          navigate('/login');
          setLoading(false);
          socket.off('RECEIVE_SAVE_USER_SUCCESS');
        });
      }
    });
  }

  // Validate form on submission only
  function validateForm() {
    const errors = {};

    if (!formData.name) errors.name = 'Name is required';
    if (!formData.surname) errors.surname = 'Surname is required';

    if (!formData.cell) {
      errors.cell = 'Cell number is required';
    } else if (formData.cell.length !== 10) {
      errors.cell = 'Cell number must be 10 digits';
    } else if (formData.cell[0] !== '0') {
      errors.cell = 'Cell number must start with 0';
    }

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!formData.password) {
      errors.password = 'Password is required';
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  }

  return (
    <div className='d-flex flex-column min-vh-100 main-about'>
      <NavigationBar />

      {/* Add padding to the top of the page to account for the fixed navbar */}
      <div style={{ paddingTop: '56px' }}></div>

      {/* Hero Section */}
      <section className='position-relative text-white mb-4'>
        <div className='position-absolute top-0 start-0 w-100 h-100'>
          <img
            src='https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=2940&auto=format&fit=crop'
            alt='Medical professionals in a modern clinic'
            className='w-100 h-100 object-fit-cover'
            style={{ filter: 'brightness(0.4)' }}
          />
        </div>

        <Container className='position-relative py-4'>
          <Row className='justify-content-center text-center'>
            <Col lg={8} className='py-3'>
              <h1 className='display-5 fw-bold mb-2'>
                Create Your ClinicPlus Account
              </h1>
              <p className='lead fs-5 mb-0'>
                Join our community to access comprehensive occupational health
                services tailored for your needs. Registration is quick, secure,
                and gives you immediate access to our booking system.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className='py-4'>
        <Row className='justify-content-center'>
          <Col lg={10}>
            {/* Registration Steps */}
            <div className='mb-4'>
              <Row className='text-center'>
                <Col md={4} className='mb-3 mb-md-0'>
                  <div
                    className='rounded-circle bg-danger text-white d-flex align-items-center justify-content-center mx-auto mb-2'
                    style={{ width: '50px', height: '50px' }}
                  >
                    <BiIdCard size={24} />
                  </div>
                  <h5 className='fw-bold mb-1'>1. Create Account</h5>
                  <p className='text-muted small mb-0'>
                    Fill in your personal details
                  </p>
                </Col>
                <Col md={4} className='mb-3 mb-md-0'>
                  <div
                    className='rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center mx-auto mb-2'
                    style={{ width: '50px', height: '50px' }}
                  >
                    <BiShield size={24} />
                  </div>
                  <h5 className='fw-bold mb-1'>2. Verify Email</h5>
                  <p className='text-muted small mb-0'>
                    Confirm your email address
                  </p>
                </Col>
                <Col md={4}>
                  <div
                    className='rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center mx-auto mb-2'
                    style={{ width: '50px', height: '50px' }}
                  >
                    <BiCheckCircle size={24} />
                  </div>
                  <h5 className='fw-bold mb-1'>3. Start Booking</h5>
                  <p className='text-muted small mb-0'>
                    Schedule your appointments
                  </p>
                </Col>
              </Row>
            </div>

            <Card className='border-0 shadow-sm'>
              <Card.Header className='bg-danger text-white p-3'>
                <h2 className='mb-0 fs-4 fw-bold'>Registration Form</h2>
                <p className='mb-0 mt-1 opacity-75 small'>
                  Please fill in all required fields to create your account
                </p>
              </Card.Header>

              <Card.Body className='p-4'>
                {generalError && (
                  <Alert variant='danger' className='mb-4'>
                    {generalError}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <Row>
                    {/* Column 1: Personal Information */}
                    <Col md={4}>
                      <h5 className='border-bottom pb-2 mb-3'>
                        Personal Information
                      </h5>

                      <div className='mb-3'>
                        <label
                          htmlFor='name'
                          className='d-flex align-items-center mb-2'
                        >
                          First Name
                          <span
                            className='ms-2 text-muted'
                            title='Enter your legal first name as it appears on your ID'
                          >
                            <BiInfoCircle />
                          </span>
                        </label>
                        <div className='d-flex'>
                          <div
                            className='input-group-text bg-light'
                            style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                          >
                            <BiUser />
                          </div>
                          <input
                            id='name'
                            name='name'
                            type='text'
                            className='form-control form-control-sm'
                            placeholder='Enter your first name'
                            value={formData.name}
                            onChange={handleChange}
                            style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                          />
                        </div>
                        <small className='text-muted d-block mt-1'>
                          Your first name will be used for identification
                          purposes.
                        </small>
                      </div>

                      <div className='mb-3'>
                        <label
                          htmlFor='surname'
                          className='d-flex align-items-center mb-2'
                        >
                          Last Name
                          <span
                            className='ms-2 text-muted'
                            title='Enter your legal last name as it appears on your ID'
                          >
                            <BiInfoCircle />
                          </span>
                        </label>
                        <div className='d-flex'>
                          <div
                            className='input-group-text bg-light'
                            style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                          >
                            <BiUser />
                          </div>
                          <input
                            id='surname'
                            name='surname'
                            type='text'
                            className='form-control form-control-sm'
                            placeholder='Enter your last name'
                            value={formData.surname}
                            onChange={handleChange}
                            style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                          />
                        </div>
                        <small className='text-muted d-block mt-1'>
                          Your last name will be used for identification
                          purposes.
                        </small>
                      </div>
                    </Col>

                    {/* Column 2: Contact Information */}
                    <Col md={4}>
                      <h5 className='border-bottom pb-2 mb-3'>
                        Contact Information
                      </h5>

                      <div className='mb-3'>
                        <label
                          htmlFor='cell'
                          className='d-flex align-items-center mb-2'
                        >
                          Cell Number
                          <span
                            className='ms-2 text-muted'
                            title='Enter a valid South African cell number starting with 0'
                          >
                            <BiInfoCircle />
                          </span>
                        </label>
                        <div className='d-flex'>
                          <div
                            className='input-group-text bg-light'
                            style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                          >
                            <BiPhone />
                          </div>
                          <input
                            id='cell'
                            name='cell'
                            type='text'
                            className='form-control form-control-sm'
                            placeholder='e.g., 0123456789'
                            value={formData.cell}
                            onChange={handleChange}
                            maxLength={10}
                            style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                          />
                        </div>
                        <small className='text-muted d-block mt-1'>
                          We'll use this number to send appointment
                          confirmations and reminders.
                        </small>
                      </div>

                      <div className='mb-3'>
                        <label
                          htmlFor='email'
                          className='d-flex align-items-center mb-2'
                        >
                          Email Address
                          <span
                            className='ms-2 text-muted'
                            title='Enter a valid email address that you have access to'
                          >
                            <BiInfoCircle />
                          </span>
                        </label>
                        <div className='d-flex'>
                          <div
                            className='input-group-text bg-light'
                            style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                          >
                            <BiEnvelope />
                          </div>
                          <input
                            id='email'
                            name='email'
                            type='email'
                            className='form-control form-control-sm'
                            placeholder='e.g., name@example.com'
                            value={formData.email}
                            onChange={handleChange}
                            style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                          />
                        </div>
                        <small className='text-muted d-block mt-1'>
                          You'll need to verify this email address. It will be
                          your username for login.
                        </small>
                      </div>
                    </Col>

                    {/* Column 3: Account Information */}
                    <Col md={4}>
                      <h5 className='border-bottom pb-2 mb-3'>
                        Account Security
                      </h5>

                      <div className='mb-3'>
                        <label
                          htmlFor='password'
                          className='d-flex align-items-center mb-2'
                        >
                          Password
                          <span
                            className='ms-2 text-muted'
                            title='Create a strong password with at least 8 characters'
                          >
                            <BiInfoCircle />
                          </span>
                        </label>
                        <div className='d-flex'>
                          <div
                            className='input-group-text bg-light'
                            style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                          >
                            <BiLock />
                          </div>
                          <input
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            className='form-control form-control-sm'
                            placeholder='Create a password'
                            value={formData.password}
                            onChange={handleChange}
                            style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                          />
                          <button
                            type='button'
                            className='btn btn-outline-secondary btn-sm'
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ marginLeft: '5px' }}
                          >
                            {showPassword ? 'Hide' : 'Show'}
                          </button>
                        </div>
                        <small className='text-muted d-block mt-1'>
                          Create a strong password with a mix of letters,
                          numbers, and symbols.
                        </small>
                      </div>

                      <div className='mb-3'>
                        <label
                          htmlFor='confirmPassword'
                          className='d-flex align-items-center mb-2'
                        >
                          Confirm Password
                          <span
                            className='ms-2 text-muted'
                            title='Re-enter your password to confirm'
                          >
                            <BiInfoCircle />
                          </span>
                        </label>
                        <div className='d-flex'>
                          <div
                            className='input-group-text bg-light'
                            style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                          >
                            <BiLock />
                          </div>
                          <input
                            id='confirmPassword'
                            name='confirmPassword'
                            type={showConfirmPassword ? 'text' : 'password'}
                            className='form-control form-control-sm'
                            placeholder='Confirm your password'
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                          />
                          <button
                            type='button'
                            className='btn btn-outline-secondary btn-sm'
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            style={{ marginLeft: '5px' }}
                          >
                            {showConfirmPassword ? 'Hide' : 'Show'}
                          </button>
                        </div>
                        <small className='text-muted d-block mt-1'>
                          Make sure both passwords match exactly.
                        </small>
                      </div>
                    </Col>
                  </Row>

                  <Row className='mt-3'>
                    <Col>
                      <div className='bg-light p-3 rounded mb-4'>
                        <h6 className='fw-bold mb-2'>Privacy Notice</h6>
                        <p className='small text-muted mb-0'>
                          By registering, you agree to our{' '}
                          <Link to='/terms' className='text-decoration-none'>
                            Terms of Service
                          </Link>{' '}
                          and{' '}
                          <Link to='/privacy' className='text-decoration-none'>
                            Privacy Policy
                          </Link>
                          . We'll use your information to provide our services,
                          send you important updates, and improve your
                          experience. Your personal data is securely stored and
                          will never be shared with third parties without your
                          consent.
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <div className='d-grid'>
                    <button
                      type='submit'
                      className='btn btn-danger py-2'
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            className='me-2'
                          />
                          Creating Account...
                        </>
                      ) : (
                        'Register Account'
                      )}
                    </button>
                  </div>

                  <div className='text-center mt-4'>
                    <Link to='/login' className='text-decoration-none'>
                      <BiArrowBack className='me-1' />
                      Already have an account? Login
                    </Link>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default RegisterPage;
