'use client';

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import { Container, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';
import {
  BiEnvelope,
  BiLock,
  BiArrowBack,
  BiInfoCircle,
  BiLogIn,
  BiUserPlus,
  BiSupport,
} from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { save } from '../../store/user';
import cookies from 'js-cookie';
import NavigationBar from '../../components/newnav';
import Footer from '../../components/newfooter';

// Utility function to check if a value exists
const exists = (i) => !isNil(i) && !isEmpty(i);

function LoginPage({ socket }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Form state
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  // UI state
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Handle input change without losing focus
  const handleInputChange = (e) => {
    console.log('Input changed:', e.target.name, e.target.value);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle login
  const doLogin = (e) => {
    e.preventDefault();

    // Basic validation
    if (!exists(formData.email) || !exists(formData.password)) {
      setError('Please enter both email and password');
      return;
    }

    setLoading(true);
    setError('');

    // Socket login functionality
    socket.emit('DO_LOGIN', {
      email: formData.email,
      password: formData.password,
    });

    socket.on('RECEIVE_LOGIN_USER_FAILED', (user) => {
      setLoading(false);
      setError(user.error);
      socket.off('RECEIVE_LOGIN_USER_FAILED');
    });

    socket.on('RECEIVE_LOGIN_USER_SUCCESS', (user) => {
      dispatch(save(user));
      cookies.set('clinicplus_client_logged_in_user', user.id, { expires: 1 });
      setError('');
      setLoading(false);
      socket.off('RECEIVE_LOGIN_USER_SUCCESS');
      navigate('/app');
    });
  };

  console.log('Logiiiiin');

  return (
    <div className='d-flex flex-column min-vh-100 main-about'>
      <NavigationBar />

      {/* Add padding to the top of the page to account for the fixed navbar */}
      <div style={{ paddingTop: '56px' }}></div>

      {/* Hero Section */}
      <section className='position-relative text-white mb-4'>
        <div className='position-absolute top-0 start-0 w-100 h-100'>
          <img
            src='https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?q=80&w=2940&auto=format&fit=crop'
            alt='Medical professionals in a modern clinic'
            className='w-100 h-100 object-fit-cover'
            style={{ filter: 'brightness(0.4)' }}
          />
        </div>

        <Container className='position-relative py-4'>
          <Row className='justify-content-center text-center'>
            <Col lg={8} className='py-3'>
              <h1 className='display-5 fw-bold mb-2'>Welcome Back</h1>
              <p className='lead fs-5 mb-0'>
                Log in to your ClinicPlus account to manage your appointments,
                access your health records, and more.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className='py-4'>
        <Row className='justify-content-center'>
          <Col md={10} lg={8} xl={6}>
            <Card className='border-0 shadow-sm'>
              <Card.Header className='bg-danger text-white p-4'>
                <h2 className='mb-0 fs-4 fw-bold'>Account Login</h2>
                <p className='mb-0 mt-1 opacity-75'>
                  Access your ClinicPlus dashboard
                </p>
              </Card.Header>

              <Card.Body className='p-4'>
                {error && (
                  <Alert variant='danger' className='mb-4'>
                    {error}
                  </Alert>
                )}

                <form onSubmit={doLogin}>
                  <div className='mb-3'>
                    <label
                      htmlFor='email'
                      className='d-flex align-items-center mb-2'
                    >
                      Email Address
                      <span
                        className='ms-2 text-muted'
                        title='Enter the email address you used to register'
                      >
                        <BiInfoCircle />
                      </span>
                    </label>
                    <div className='d-flex'>
                      <div
                        className='input-group-text bg-light'
                        style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                      >
                        <BiEnvelope />
                      </div>
                      <input
                        id='email'
                        type='email'
                        name='email'
                        className='form-control'
                        placeholder='Enter your email address'
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                      />
                    </div>
                    <small className='text-muted d-block mt-1'>
                      This is the email address you used when creating your
                      account.
                    </small>
                  </div>

                  <div className='mb-4'>
                    <label
                      htmlFor='password'
                      className='d-flex align-items-center mb-2'
                    >
                      Password
                      <span
                        className='ms-2 text-muted'
                        title='Enter your account password'
                      >
                        <BiInfoCircle />
                      </span>
                    </label>
                    <div className='d-flex'>
                      <div
                        className='input-group-text bg-light'
                        style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                      >
                        <BiLock />
                      </div>
                      <input
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        name='password'
                        className='form-control'
                        placeholder='Enter your password'
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                        style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                      />
                      <button
                        type='button'
                        className='btn btn-outline-secondary'
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ marginLeft: '5px' }}
                      >
                        {showPassword ? 'Hide' : 'Show'}
                      </button>
                    </div>
                    <small className='text-muted d-block mt-1'>
                      Passwords are case-sensitive.{' '}
                      <Link
                        to='/forgot-password'
                        className='text-decoration-none'
                      >
                        Forgot your password?
                      </Link>
                    </small>
                  </div>

                  <div className='d-grid mb-4'>
                    <button
                      type='submit'
                      className='btn btn-danger btn-lg py-2'
                      disabled={
                        !exists(formData.email) ||
                        !exists(formData.password) ||
                        loading
                      }
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            className='me-2'
                          />
                          Logging in...
                        </>
                      ) : (
                        <>
                          <BiLogIn className='me-2' />
                          Login to Your Account
                        </>
                      )}
                    </button>
                  </div>

                  <div className='text-center mb-4'>
                    <p className='mb-2'>Don't have an account?</p>
                    <button
                      type='button'
                      className='btn btn-outline-danger px-4'
                      onClick={() => navigate('/register')}
                    >
                      <BiUserPlus className='me-2' />
                      Create an Account
                    </button>
                  </div>
                </form>

                <div className='bg-light p-3 rounded'>
                  <h6 className='fw-bold d-flex align-items-center'>
                    <BiSupport className='me-2' />
                    Need Help?
                  </h6>
                  <p className='small text-muted mb-0'>
                    By logging in, you gain access to a range of features and
                    services designed to support your occupational health needs.
                    If you encounter any issues or need assistance, please
                    contact our support team at{' '}
                    <a
                      href='mailto:bookings@clinicpluswtb.co.za'
                      className='text-decoration-none'
                    >
                      bookings@clinicpluswtb.co.za
                    </a>
                  </p>
                </div>
              </Card.Body>
            </Card>

            <div className='text-center mt-4'>
              <Link to='/' className='text-decoration-none'>
                <BiArrowBack className='me-1' />
                Return to Home Page
              </Link>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default LoginPage;
