'use client';

import { Container } from 'react-bootstrap';
import { useEffect } from 'react';

function NavigationBar({ activePage = 'home' }) {
  // Initialize Bootstrap JavaScript for navbar toggle
  useEffect(() => {
    // Make sure Bootstrap JavaScript is properly initialized
    if (typeof document !== 'undefined') {
      // Check if Bootstrap is available
      if (typeof window.bootstrap === 'undefined') {
        // If Bootstrap JS is not available globally, import it
        import('bootstrap/dist/js/bootstrap.bundle.min.js')
          .then(() => {
            // Initialize all tooltips and popovers if needed
            const tooltipTriggerList = document.querySelectorAll(
              '[data-bs-toggle="tooltip"]'
            );
            const tooltipList = [...tooltipTriggerList].map(
              (tooltipTriggerEl) =>
                new window.bootstrap.Tooltip(tooltipTriggerEl)
            );

            // Initialize any dropdowns if needed
            const dropdownTriggerList = document.querySelectorAll(
              '[data-bs-toggle="dropdown"]'
            );
            const dropdownList = [...dropdownTriggerList].map(
              (dropdownTriggerEl) =>
                new window.bootstrap.Dropdown(dropdownTriggerEl)
            );

            // Ensure navbar toggler works
            const navbarToggler = document.querySelector('.navbar-toggler');
            if (navbarToggler) {
              navbarToggler.addEventListener('click', function () {
                const target = document.querySelector(
                  this.getAttribute('data-bs-target')
                );
                if (target) {
                  target.classList.toggle('show');
                }
              });
            }
          })
          .catch((err) => console.error('Failed to load Bootstrap JS:', err));
      }
    }
  }, []);

  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-dark fixed-top'>
      <Container>
        <a className='navbar-brand' href='/'>
          <img
            src='/cp-logo-full-white.svg'
            alt='ClinicPlus Logo'
            height='30'
            className='d-inline-block align-top'
          />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <a
                className={`nav-link ${activePage === 'home' ? 'active' : ''}`}
                href='/'
              >
                Home
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${
                  activePage === 'services' ? 'active' : ''
                }`}
                href='/services'
              >
                Services
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${activePage === 'about' ? 'active' : ''}`}
                href='/about'
              >
                About Us
              </a>
            </li>
          </ul>
          <div className='d-flex'>
            <a href='/login' className='btn btn-outline-light me-2'>
              Login
            </a>
            <a href='/register' className='btn btn-danger'>
              Register
            </a>
          </div>
        </div>
      </Container>
    </nav>
  );
}

export default NavigationBar;
