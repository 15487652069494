import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import {
  BiBuilding,
  BiCheckShield,
  BiCustomize,
  BiHealth,
  BiHeartCircle,
  BiTargetLock,
  BiGroup,
  BiPhone,
  BiEnvelope,
  BiCalendarCheck,
  BiCertification,
  BiFirstAid,
  BiDumbbell,
  BiShield,
  BiBookReader,
} from 'react-icons/bi';
import NavigationBar from '../components/newnav';
import Footer from '../components/newfooter';

function AboutUs() {
  return (
    <div className='d-flex flex-column min-vh-100 main-about'>
      <NavigationBar />

      {/* Add padding to the top of the page to account for the fixed navbar */}
      <div style={{ paddingTop: '56px' }}></div>

      {/* Hero Section */}
      <div className='position-relative text-white mb-5'>
        <div className='position-absolute top-0 start-0 w-100 h-100'>
          <img
            src='https://images.unsplash.com/photo-1576091160550-2173dba999ef?q=80&w=2940&auto=format&fit=crop'
            alt='Medical professionals in a meeting'
            className='w-100 h-100 object-fit-cover'
            style={{ filter: 'brightness(0.4)' }}
          />
        </div>

        <Container className='position-relative py-5'>
          <Row className='py-5 justify-content-center text-center'>
            <Col lg={8} className='py-lg-5'>
              <h1 className='display-4 fw-bold mb-4'>About ClinicPlus</h1>
              <p className='lead fs-4 mb-0'>
                Your dedicated partner in occupational health management and
                consulting
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Introduction Section */}
      <Container className='mb-5'>
        <Row className='align-items-center'>
          <Col lg={6} className='mb-4 mb-lg-0'>
            <div className='p-4 bg-light rounded-3 border-start border-5 border-danger'>
              <p className='lead mb-0'>
                At ClinicPlus, we're more than just a company – we're your
                dedicated partners in fostering a healthier and more productive
                workplace. With a strong focus on Occupational Health Management
                and Consulting, we are committed to supporting both employees
                and businesses on their journey to optimal well-being.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <img
              src='https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?q=80&w=2940&auto=format&fit=crop'
              alt='Healthcare professionals'
              className='img-fluid rounded-3 shadow-lg'
            />
          </Col>
        </Row>
      </Container>

      {/* Vision Section */}
      <Container className='mb-5 py-5 bg-light rounded-3'>
        <Row className='justify-content-center text-center mb-4'>
          <Col lg={8}>
            <BiTargetLock className='text-danger mb-3' size={50} />
            <h2 className='display-6 fw-bold mb-4'>Our Vision</h2>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col lg={8} className='text-center'>
            <p className='lead'>
              Our vision is to create a work environment where health is
              prioritized and integrated seamlessly into the fabric of everyday
              operations. We envision a world where employees thrive physically,
              mentally, and emotionally, leading to increased productivity, job
              satisfaction, and overall business success.
            </p>
          </Col>
        </Row>
      </Container>

      {/* Who We Are Section */}
      <Container className='mb-5'>
        <Row className='align-items-center'>
          <Col lg={6} className='order-lg-2 mb-4 mb-lg-0'>
            <h2 className='display-6 fw-bold mb-4'>
              <BiGroup className='text-danger me-2' size={40} />
              Who We Are
            </h2>
            <p className='lead'>
              ClinicPlus is a South Africa-based leader in Occupational Health
              Management and Consulting. With a team of experienced medical
              professionals, wellness experts, and technology enthusiasts, we
              bring a comprehensive approach to enhancing workplace health and
              safety.
            </p>
            <div className='d-flex align-items-center mt-4'>
              <Badge bg='danger' className='me-2 p-2'>
                Est. 2007
              </Badge>
              <Badge bg='dark' className='me-2 p-2'>
                South Africa Based
              </Badge>
              <Badge bg='secondary' className='p-2'>
                Industry Leader
              </Badge>
            </div>
          </Col>
          <Col lg={6} className='order-lg-1'>
            <div className='position-relative'>
              <img
                src='https://images.unsplash.com/photo-1551190822-a9333d879b1f?q=80&w=2940&auto=format&fit=crop'
                alt='Medical team'
                className='img-fluid rounded-3 shadow-lg'
              />
              <div className='position-absolute top-0 start-0 w-100 h-100 bg-danger opacity-25 rounded-3'></div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* What Sets Us Apart Section */}
      <Container className='mb-5'>
        <Row className='justify-content-center text-center mb-5'>
          <Col lg={8}>
            <h2 className='display-6 fw-bold mb-3'>
              <BiCheckShield className='text-danger me-2' size={40} />
              What Sets Us Apart
            </h2>
            <p className='lead'>
              Our commitment to excellence and innovation makes ClinicPlus the
              preferred choice for occupational health services.
            </p>
          </Col>
        </Row>

        <Row className='g-4'>
          <Col md={6}>
            <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <Card.Body className='p-4'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='bg-danger p-3 rounded-circle text-white me-3'>
                    <BiCertification size={24} />
                  </div>
                  <h3 className='h5 fw-bold mb-0'>Expertise</h3>
                </div>
                <Card.Text>
                  Our team of qualified medical professionals and consultants
                  are well-versed in the complexities of occupational health. We
                  stay up-to-date with industry regulations and best practices
                  to ensure that our clients receive accurate and reliable
                  guidance.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <Card.Body className='p-4'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='bg-danger p-3 rounded-circle text-white me-3'>
                    <BiCustomize size={24} />
                  </div>
                  <h3 className='h5 fw-bold mb-0'>Tailored Solutions</h3>
                </div>
                <Card.Text>
                  We understand that every business is unique. That's why we
                  offer customized solutions that address the specific needs and
                  challenges of each client. From small enterprises to large
                  corporations, our approach is adaptable and effective.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <Card.Body className='p-4'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='bg-danger p-3 rounded-circle text-white me-3'>
                    <BiHeartCircle size={24} />
                  </div>
                  <h3 className='h5 fw-bold mb-0'>Holistic Approach</h3>
                </div>
                <Card.Text>
                  Health extends beyond just physical well-being. ClinicPlus
                  takes a holistic approach that considers mental and emotional
                  health as integral components of overall well-being. Our
                  wellness programs reflect this commitment.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <Card.Body className='p-4'>
                <div className='d-flex align-items-center mb-3'>
                  <div className='bg-danger p-3 rounded-circle text-white me-3'>
                    <BiBuilding size={24} />
                  </div>
                  <h3 className='h5 fw-bold mb-0'>Cutting-Edge Technology</h3>
                </div>
                <Card.Text>
                  We leverage technology to simplify the process of booking
                  appointments, accessing health records, and tracking progress.
                  Our online platform ensures convenience and efficiency for
                  both clients and their employees.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Our Services Section */}
      <Container className='mb-5 py-5 bg-light rounded-3'>
        <Row className='justify-content-center text-center mb-5'>
          <Col lg={8}>
            <h2 className='display-6 fw-bold mb-3'>
              <BiHealth className='text-danger me-2' size={40} />
              Our Services
            </h2>
            <p className='lead'>
              Comprehensive occupational health solutions tailored to your
              business needs
            </p>
          </Col>
        </Row>

        <Row className='g-4'>
          <Col md={6} lg={3}>
            <Card className='text-center h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <Card.Body className='p-4'>
                <div
                  className='bg-danger text-white rounded-circle mx-auto mb-4 d-flex align-items-center justify-content-center'
                  style={{ width: '80px', height: '80px' }}
                >
                  <BiFirstAid size={40} />
                </div>
                <h3 className='h5 fw-bold mb-3'>Medical Assessments</h3>
                <Card.Text>
                  From pre-employment medicals to regular health check-ups, we
                  provide a range of medical assessments that promote a healthy
                  workforce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={3}>
            <Card className='text-center h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <Card.Body className='p-4'>
                <div
                  className='bg-danger text-white rounded-circle mx-auto mb-4 d-flex align-items-center justify-content-center'
                  style={{ width: '80px', height: '80px' }}
                >
                  <BiDumbbell size={40} />
                </div>
                <h3 className='h5 fw-bold mb-3'>Wellness Programs</h3>
                <Card.Text>
                  Our wellness initiatives are designed to educate, motivate,
                  and empower employees to make positive lifestyle choices.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={3}>
            <Card className='text-center h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <Card.Body className='p-4'>
                <div
                  className='bg-danger text-white rounded-circle mx-auto mb-4 d-flex align-items-center justify-content-center'
                  style={{ width: '80px', height: '80px' }}
                >
                  <BiShield size={40} />
                </div>
                <h3 className='h5 fw-bold mb-3'>
                  Health & Safety Consultations
                </h3>
                <Card.Text>
                  We offer expert guidance on creating safe and compliant work
                  environments, reducing workplace risks, and improving overall
                  occupational health standards.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={3}>
            <Card className='text-center h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <Card.Body className='p-4'>
                <div
                  className='bg-danger text-white rounded-circle mx-auto mb-4 d-flex align-items-center justify-content-center'
                  style={{ width: '80px', height: '80px' }}
                >
                  <BiBookReader size={40} />
                </div>
                <h3 className='h5 fw-bold mb-3'>Training & Workshops</h3>
                <Card.Text>
                  Through interactive workshops and training sessions, we equip
                  both employees and management with the knowledge and skills
                  needed to maintain a healthy workplace.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Team Section */}
      <Container className='mb-5'>
        <Row className='justify-content-center text-center mb-5'>
          <Col lg={8}>
            <h2 className='display-6 fw-bold mb-3'>Meet Our Leadership</h2>
            <p className='lead'>
              Our experienced team of healthcare professionals is dedicated to
              your wellbeing
            </p>
          </Col>
        </Row>

        <Row className='g-4 justify-content-center'>
          <Col md={6} lg={4}>
            <Card className='text-center h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
              <div className='position-relative'>
                <img
                  src='https://images.unsplash.com/photo-1559839734-2b71ea197ec2?q=80&w=2940&auto=format&fit=crop'
                  alt='Dr. Bertha van der Spuy-Lombaard'
                  className='card-img-top'
                  style={{ height: '300px', objectFit: 'cover' }}
                />
                <div className='position-absolute bottom-0 start-0 w-100 bg-dark bg-opacity-75 text-white p-2'>
                  <h5 className='mb-0'>Founder & CEO</h5>
                </div>
              </div>
              <Card.Body className='p-4'>
                <h3 className='h5 fw-bold mb-2'>
                  Dr. Bertha van der Spuy-Lombaard
                </h3>
                <p className='text-white mb-3'>
                  MD, Occupational Health Specialist
                </p>
                <Card.Text>
                  Founded ClinicPlus in 2007 with a vision to transform
                  occupational health services in South Africa.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Contact Section */}
      <Container className='mb-5'>
        <Row>
          <Col lg={12}>
            <Card className='border-0 bg-gradient text-white shadow-lg overflow-hidden'>
              <Card.Body
                className='p-5'
                style={{
                  background: 'linear-gradient(to right, #dc3545, #9a0000)',
                }}
              >
                <Row className='align-items-center'>
                  <Col lg={8}>
                    <h2 className='display-6 fw-bold mb-3'>Contact Us</h2>
                    <p className='lead mb-4'>
                      Ready to embark on a journey towards a healthier and more
                      productive workforce? Get in touch with ClinicPlus today.
                    </p>
                    <div className='d-flex flex-column flex-md-row gap-4 mb-4'>
                      <div className='d-flex align-items-center'>
                        <div className='bg-white text-danger rounded-circle p-2 me-3'>
                          <BiPhone size={24} />
                        </div>
                        <div>
                          <p className='mb-0 fw-bold'>Phone</p>
                          <a
                            href='tel:+27136562020'
                            className='text-white text-decoration-none'
                          >
                            +27 13 656 2020
                          </a>
                        </div>
                      </div>
                      <div className='d-flex align-items-center'>
                        <div className='bg-white text-danger rounded-circle p-2 me-3'>
                          <BiEnvelope size={24} />
                        </div>
                        <div>
                          <p className='mb-0 fw-bold'>Email</p>
                          <a
                            href='mailto:bookings@clinicpluswtb.co.za'
                            className='text-white text-decoration-none'
                          >
                            bookings@clinicpluswtb.co.za
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} className='text-center text-lg-end'>
                    <a href='/register'>
                      <Button
                        variant='light'
                        size='lg'
                        className='px-4 py-3 text-danger fw-bold'
                      >
                        <BiCalendarCheck className='me-2' size={20} />
                        Book an Appointment
                      </Button>
                    </a>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default AboutUs;
