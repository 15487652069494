'use client';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import {
  BiShield,
  BiCheckCircle,
  BiGroup,
  BiCar,
  BiCalendar,
  BiAward,
  BiCheck,
  BiDownload,
  BiStar,
  BiMap,
  BiPhone,
  BiEnvelope,
  BiTime,
  BiQuestionMark,
} from 'react-icons/bi';
import { useState, useEffect } from 'react';
import NavigationBar from '../components/newnav';
import Footer from '../components/newfooter';
import { Helmet } from 'react-helmet';

function AnimatedHeadlines({ headlines }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Start fade out
      setIsVisible(false);

      // After fade out completes, change the headline and fade in
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % headlines.length);
        setIsVisible(true);
      }, 1000); // This should match the CSS transition time
    }, 4000); // Total time each headline is shown

    return () => clearInterval(intervalId);
  }, [headlines.length]);

  return (
    <div className='animated-headline-container mb-4'>
      <h2
        className={`animated-headline lead fs-2 fw-bold ${
          isVisible ? 'visible' : 'invisible'
        }`}
      >
        {headlines[currentIndex]}
      </h2>
    </div>
  );
}

export default function Home() {
  const [showInstallBanner, setShowInstallBanner] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    // Check if the app is already installed
    const isAppInstalled = window.matchMedia(
      '(display-mode: standalone)'
    ).matches;

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the default browser install prompt
      e.preventDefault();
      // Save the event so it can be triggered later
      setDeferredPrompt(e);
      // Show the install banner
      if (!isAppInstalled) {
        setShowInstallBanner(true);
      }
    });

    // Register service worker for PWA functionality
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then((registration) => {
            console.log(
              'Service Worker registered with scope:',
              registration.scope
            );
          })
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      });
    }
  }, []);

  const handleInstallClick = () => {
    if (!deferredPrompt) return;

    // Show the install prompt
    deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      // Clear the saved prompt since it can't be used again
      setDeferredPrompt(null);
      setShowInstallBanner(false);
    });
  };

  return (
    <div className='d-flex flex-column min-vh-100'>
      <Helmet>
        <title>
          ClinicPlus - Premier Occupational Health Services in South Africa
        </title>
        <meta
          name='description'
          content='ClinicPlus offers comprehensive occupational health services for mining and construction companies in South Africa. Health assessments, wellness programs, and mobile clinic services.'
        />
        <meta
          name='keywords'
          content='clinic plus,clinic plus wtb, occupational health, workplace health, mining health services, construction health services, medical assessments, wellness programs, South Africa, Witbank, Mpumalanga'
        />
        <meta name='author' content='ClinicPlus' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta name='robots' content='index, follow' />
        <meta
          property='og:title'
          content='ClinicPlus - Premier Occupational Health Services in South Africa'
        />
        <meta
          property='og:description'
          content='Comprehensive occupational health services for mining and construction companies. Promoting healthy employees and thriving businesses since 2007.'
        />
        <meta property='og:image' content='/og-image.jpg' />
        <meta property='og:url' content='https://clinicplus.co.za' />
        <meta property='og:type' content='website' />
        <meta name='twitter:card' content='summary_large_image' />
        <link rel='canonical' href='https://clinicplus.co.za' />
        <link rel='manifest' href='/manifest.json' />
        <meta name='theme-color' content='#dc3545' />
        <link rel='apple-touch-icon' href='/icons/icon-192x192.png' />

        {/* Schema.org structured data for Local Business */}
        <script type='application/ld+json'>
          {`
            {
              "@context": "https://schema.org",
              "@type": "MedicalBusiness",
              "name": "ClinicPlus",
              "image": "https://clinicplus.co.za/cp-logo-full-white.svg",
              "url": "https://clinicplus.co.za",
              "telephone": "+27136560044",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "123 Health Street",
                "addressLocality": "Witbank",
                "addressRegion": "Mpumalanga",
                "postalCode": "1035",
                "addressCountry": "ZA"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": -25.8717,
                "longitude": 29.2312
              },
              "openingHoursSpecification": [
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                  "opens": "08:00",
                  "closes": "17:00"
                },
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": "Saturday",
                  "opens": "09:00",
                  "closes": "13:00"
                }
              ],
              "sameAs": [
                "https://www.facebook.com/clinicplus",
                "https://twitter.com/clinicplus",
                "https://www.linkedin.com/company/clinicplus"
              ],
              "priceRange": "$$",
              "description": "ClinicPlus offers comprehensive occupational health services for mining and construction companies in South Africa. Our services include health assessments, wellness programs, and mobile clinic services."
            }
          `}
        </script>
      </Helmet>

      <NavigationBar activePage='home' />

      {/* Install App Banner */}
      {showInstallBanner && (
        <Alert variant='info' className='mb-0 rounded-0 text-center'>
          <Container>
            <div className='d-flex justify-content-between align-items-center'>
              <span>
                <strong>Install ClinicPlus:</strong> Access our services offline
                and get a better experience!
              </span>
              <Button
                variant='outline-dark'
                size='sm'
                onClick={handleInstallClick}
                className='ms-3'
              >
                <BiDownload className='me-1' /> Install App
              </Button>
            </div>
          </Container>
        </Alert>
      )}

      {/* Add padding to the top of the page to account for the fixed navbar */}
      <div style={{ paddingTop: '56px' }}></div>

      {/* Hero Section */}
      <section className='position-relative text-white'>
        <div className='position-absolute top-0 start-0 w-100 h-100'>
          <img
            src='/home.jpg'
            alt='Medical professionals providing occupational health services in a modern clinic setting'
            className='w-100 h-100 object-fit-cover'
            style={{ filter: 'brightness(0.4)' }}
          />
        </div>

        <Container className='position-relative py-5 py-md-6'>
          <Row className='py-5'>
            <Col lg={8} className='py-lg-5'>
              <img
                src='/cp-logo-full-white.svg'
                alt='ClinicPlus Logo - Occupational Health Services'
                width='240'
                height='80'
                className='mb-4'
              />

              <h1 className='display-4 fw-bold mb-4'>
                Premier Occupational Health Services for Mining & Construction
                in South Africa
              </h1>

              <AnimatedHeadlines
                headlines={[
                  'Promoting Healthy Employees & Safe Workplaces',
                  'Comprehensive Occupational Health Solutions',
                  'Your Trusted Partner in Workplace Wellness',
                  'Mobile Health Services Throughout Mpumalanga',
                ]}
              />

              <p className='lead fs-4 mb-4 text-light'>
                Dedicated to fostering healthy employees and thriving businesses
                since 2007. Our comprehensive occupational health services
                ensure workplace safety, regulatory compliance, and employee
                wellbeing.
              </p>

              <div className='d-flex flex-column flex-sm-row gap-3'>
                <a href='/login'>
                  <Button variant='danger' size='lg' className='px-4 py-3'>
                    Schedule a Consultation
                  </Button>
                </a>
                <a href='/services'>
                  <Button
                    variant='outline-light'
                    size='lg'
                    className='px-4 py-3'
                  >
                    Explore Our Services
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Services Section */}
      <section className='bg-white py-5' id='services'>
        <Container>
          <div className='text-center mb-5'>
            <h2 className='display-5 fw-bold mb-3'>
              Comprehensive Occupational Health Services
            </h2>
            <p
              className='lead text-muted mx-auto'
              style={{ maxWidth: '800px' }}
            >
              ClinicPlus offers a full spectrum of occupational health services
              tailored specifically for mining and construction companies
              throughout Mpumalanga and beyond.
            </p>
          </div>

          <Row className='g-4'>
            <Col md={6} lg={4}>
              <ServiceCard
                icon={<BiShield className='fs-1 text-danger' />}
                title='Health and Safety Inductions'
                description='Comprehensive workplace safety inductions and training to ensure regulatory compliance, reduce incidents, and create a culture of safety awareness.'
              />
            </Col>

            <Col md={6} lg={4}>
              <ServiceCard
                icon={<BiCheckCircle className='fs-1 text-danger' />}
                title='Fitness-to-work Assessments'
                description='Thorough medical evaluations to ensure employees are physically and mentally fit for their specific roles, reducing workplace accidents and injuries.'
              />
            </Col>

            <Col md={6} lg={4}>
              <ServiceCard
                icon={<BiGroup className='fs-1 text-danger' />}
                title='Wellness Campaigns'
                description='Proactive health initiatives including screenings, education, and support programs to promote overall employee wellbeing and productivity.'
              />
            </Col>

            <Col md={6} lg={4}>
              <ServiceCard
                icon={<BiCar className='fs-1 text-danger' />}
                title='Mobile Occupational Health'
                description='On-site health services throughout Mpumalanga, bringing comprehensive care directly to your workplace for maximum convenience and efficiency.'
              />
            </Col>

            <Col md={6} lg={4}>
              <ServiceCard
                icon={<BiCalendar className='fs-1 text-danger' />}
                title='Dover Vienna Testing'
                description='Specialized testing for specific occupational health requirements in mining and construction, ensuring compliance with industry regulations.'
              />
            </Col>

            <Col md={6} lg={4}>
              <ServiceCard
                icon={<BiAward className='fs-1 text-danger' />}
                title='Comprehensive Care'
                description='From pre-employment assessments to specialized testing and ongoing health monitoring, we offer a full spectrum of occupational health services.'
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Why Choose Us Section */}
      <section className='bg-light py-5' id='why-choose-us'>
        <Container>
          <Row className='align-items-center g-5'>
            <Col lg={6}>
              <div
                className='position-relative rounded overflow-hidden shadow-lg'
                style={{ height: '500px' }}
              >
                <img
                  src='/home.jpg'
                  alt='ClinicPlus Mobile Clinic providing on-site occupational health services'
                  className='w-100 h-100 object-fit-cover'
                />
              </div>
            </Col>

            <Col lg={6}>
              <h2 className='display-5 fw-bold mb-4'>Why Choose ClinicPlus</h2>
              <p className='lead mb-4'>
                Your trusted partner in occupational health since 2007,
                delivering specialized services tailored for the unique needs of
                mining and construction companies throughout South Africa.
              </p>

              <div className='d-flex flex-column gap-4'>
                <FeatureItem
                  title='Industry-Specific Expertise'
                  description='Specialized services tailored for the unique occupational health needs of mining and construction companies in Witbank, Hendrina, and throughout Mpumalanga province.'
                />

                <FeatureItem
                  title='Mobile Clinic Services'
                  description='Our state-of-the-art mobile clinic brings comprehensive occupational health services directly to your worksite, maximizing convenience and minimizing downtime.'
                />

                <FeatureItem
                  title='Regulatory Compliance'
                  description='Stay compliant with all South African occupational health and safety regulations with our comprehensive assessment and documentation services.'
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials Section */}
      <section className='bg-white py-5' id='testimonials'>
        <Container>
          <div className='text-center mb-5'>
            <h2 className='display-5 fw-bold mb-3'>What Our Clients Say</h2>
            <p
              className='lead text-muted mx-auto'
              style={{ maxWidth: '800px' }}
            >
              Hear from mining and construction companies who have experienced
              the ClinicPlus difference.
            </p>
          </div>

          <Row className='g-4'>
            <Col md={6} lg={4}>
              <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                <Card.Body className='p-4'>
                  <div className='mb-3 text-warning'>
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar />
                  </div>
                  <p className='fst-italic mb-4'>
                    "ClinicPlus has transformed our approach to employee health.
                    Their mobile clinic services have reduced downtime and their
                    comprehensive assessments have helped us maintain a
                    healthier workforce."
                  </p>
                  <div className='d-flex align-items-center'>
                    <div
                      className='rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center me-3'
                      style={{ width: '50px', height: '50px' }}
                    >
                      <span className='fw-bold'>JM</span>
                    </div>
                    <div>
                      <h5 className='mb-0 fw-bold'>John Mokoena</h5>
                      <p className='mb-0 text-muted'>
                        Safety Manager, Mpumalanga Mining Ltd
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                <Card.Body className='p-4'>
                  <div className='mb-3 text-warning'>
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar />
                  </div>
                  <p className='fst-italic mb-4'>
                    "The wellness programs implemented by ClinicPlus have
                    significantly reduced our absenteeism rates. Their team is
                    professional, thorough, and genuinely cares about the health
                    of our employees."
                  </p>
                  <div className='d-flex align-items-center'>
                    <div
                      className='rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center me-3'
                      style={{ width: '50px', height: '50px' }}
                    >
                      <span className='fw-bold'>SN</span>
                    </div>
                    <div>
                      <h5 className='mb-0 fw-bold'>Sarah Nkosi</h5>
                      <p className='mb-0 text-muted'>
                        HR Director, BuildRight Construction
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
                <Card.Body className='p-4'>
                  <div className='mb-3 text-warning'>
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar className='me-1' />
                    <BiStar />
                  </div>
                  <p className='fst-italic mb-4'>
                    "ClinicPlus has been instrumental in helping us maintain
                    compliance with health and safety regulations. Their
                    documentation is thorough and their staff is knowledgeable
                    and efficient."
                  </p>
                  <div className='d-flex align-items-center'>
                    <div
                      className='rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center me-3'
                      style={{ width: '50px', height: '50px' }}
                    >
                      <span className='fw-bold'>DV</span>
                    </div>
                    <div>
                      <h5 className='mb-0 fw-bold'>David van der Merwe</h5>
                      <p className='mb-0 text-muted'>
                        Operations Manager, SA Minerals
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About Section */}
      <section className='bg-danger text-white py-5' id='about'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} className='text-center'>
              <h2 className='display-5 fw-bold mb-4'>Our Story</h2>
              <p className='lead mb-4'>
                Founded in 2007 by Dr. Bertha van der Spuy-Lombaard, ClinicPlus
                has been at the forefront of occupational health services for
                over 15 years. Our mission is to promote healthy workplaces
                through exceptional care and tailored solutions for mining and
                construction companies throughout South Africa.
              </p>
              <div className='d-flex justify-content-center'>
                <div
                  style={{ height: '4px', width: '80px' }}
                  className='bg-white rounded'
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* FAQ Section */}
      <section className='bg-white py-5' id='faq'>
        <Container>
          <div className='text-center mb-5'>
            <h2 className='display-5 fw-bold mb-3'>
              Frequently Asked Questions
            </h2>
            <p
              className='lead text-muted mx-auto'
              style={{ maxWidth: '800px' }}
            >
              Find answers to common questions about our occupational health
              services
            </p>
          </div>

          <Row className='g-4 justify-content-center'>
            <Col lg={10}>
              <Card className='border-0 shadow-sm mb-4'>
                <Card.Body className='p-4'>
                  <div className='d-flex'>
                    <div className='flex-shrink-0'>
                      <div className='rounded-circle bg-danger p-3 text-white'>
                        <BiQuestionMark size={24} />
                      </div>
                    </div>
                    <div className='ms-3'>
                      <h3 className='h5 fw-bold mb-2'>
                        What occupational health services do you offer?
                      </h3>
                      <p className='mb-0'>
                        We offer a comprehensive range of occupational health
                        services including pre-employment assessments, periodic
                        health evaluations, fitness-to-work examinations,
                        wellness programs, health and safety training, mobile
                        clinic services, and specialized testing for mining and
                        construction industries.
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className='border-0 shadow-sm mb-4'>
                <Card.Body className='p-4'>
                  <div className='d-flex'>
                    <div className='flex-shrink-0'>
                      <div className='rounded-circle bg-danger p-3 text-white'>
                        <BiQuestionMark size={24} />
                      </div>
                    </div>
                    <div className='ms-3'>
                      <h3 className='h5 fw-bold mb-2'>
                        Do you provide on-site services?
                      </h3>
                      <p className='mb-0'>
                        Yes, our mobile clinic brings occupational health
                        services directly to your workplace throughout
                        Mpumalanga province. This minimizes disruption to your
                        operations and maximizes convenience for your employees.
                        Our mobile unit is fully equipped to conduct most
                        assessments and tests on-site.
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className='border-0 shadow-sm mb-4'>
                <Card.Body className='p-4'>
                  <div className='d-flex'>
                    <div className='flex-shrink-0'>
                      <div className='rounded-circle bg-danger p-3 text-white'>
                        <BiQuestionMark size={24} />
                      </div>
                    </div>
                    <div className='ms-3'>
                      <h3 className='h5 fw-bold mb-2'>
                        How can occupational health services benefit my company?
                      </h3>
                      <p className='mb-0'>
                        Our services help reduce workplace injuries and
                        illnesses, decrease absenteeism, improve productivity,
                        ensure regulatory compliance, lower healthcare costs,
                        and create a healthier, more engaged workforce. Most
                        clients see measurable improvements within 6-12 months
                        of implementing our programs.
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Location Section */}
      <section className='bg-light py-5' id='location'>
        <Container>
          <Row className='align-items-center'>
            <Col lg={6} className='mb-4 mb-lg-0'>
              <h2 className='display-5 fw-bold mb-4'>Our Location</h2>
              <p className='lead mb-4'>
                Conveniently located in Witbank, Mpumalanga, with mobile
                services available throughout the province.
              </p>

              <div className='d-flex align-items-center mb-3'>
                <div className='flex-shrink-0'>
                  <div className='rounded-circle bg-danger p-2 text-white'>
                    <BiMap size={24} />
                  </div>
                </div>
                <div className='ms-3'>
                  <h5 className='fw-bold mb-1'>Address</h5>
                  <p className='mb-0'>
                    2 Churchill Street, Witbank Emalahleni, 1035
                  </p>
                </div>
              </div>

              <div className='d-flex align-items-center mb-3'>
                <div className='flex-shrink-0'>
                  <div className='rounded-circle bg-danger p-2 text-white'>
                    <BiPhone size={24} />
                  </div>
                </div>
                <div className='ms-3'>
                  <h5 className='fw-bold mb-1'>Phone</h5>
                  <p className='mb-0'>013 656 2020</p>
                </div>
              </div>

              <div className='d-flex align-items-center mb-3'>
                <div className='flex-shrink-0'>
                  <div className='rounded-circle bg-danger p-2 text-white'>
                    <BiEnvelope size={24} />
                  </div>
                </div>
                <div className='ms-3'>
                  <h5 className='fw-bold mb-1'>Email</h5>
                  <p className='mb-0'>bookings@clinicpluswtb.co.za</p>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <div className='flex-shrink-0'>
                  <div className='rounded-circle bg-danger p-2 text-white'>
                    <BiTime size={24} />
                  </div>
                </div>
                <div className='ms-3'>
                  <h5 className='fw-bold mb-1'>Hours</h5>
                  <p className='mb-0'>Monday - Friday: 8:00 AM - 5:00 PM</p>
                  <p className='mb-0'>Saturday: 9:00 AM - 1:00 PM</p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className='ratio ratio-16x9 rounded overflow-hidden shadow-lg'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114412.71813231265!2d29.16121755!3d-25.87168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1eebbf1f39a9b337%3A0x27418e0e4b1b1e2!2sEmalahleni%2C%20South%20Africa!5e0!3m2!1sen!2sus!4v1649252525895!5m2!1sen!2sus'
                  width='600'
                  height='450'
                  style={{ border: 0 }}
                  allowFullScreen=''
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                  title='ClinicPlus Location Map'
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* CTA Section */}
      <section className='bg-white py-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={10} lg={8}>
              <Card className='border-0 bg-gradient text-white shadow-lg overflow-hidden'>
                <Card.Body
                  className='p-5 text-center'
                  style={{
                    background: 'linear-gradient(to right, #dc3545, #9a0000)',
                  }}
                >
                  <h2 className='display-5 fw-bold mb-3'>
                    Ready to Prioritize Workplace Health?
                  </h2>
                  <p className='lead mb-4'>
                    Join ClinicPlus today and take the first step towards a
                    healthier, more productive workplace. Schedule a
                    consultation to discuss your specific occupational health
                    needs.
                  </p>
                  <div className='d-flex flex-column flex-sm-row justify-content-center gap-3'>
                    <a href='/login'>
                      <Button
                        variant='light'
                        size='lg'
                        className='px-4 py-3 text-danger fw-bold'
                      >
                        Schedule a Consultation
                      </Button>
                    </a>
                    <a href='/about'>
                      <Button
                        variant='outline-light'
                        size='lg'
                        className='px-4 py-3'
                      >
                        Contact Us
                      </Button>
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

function ServiceCard({ icon, title, description }) {
  return (
    <Card className='h-100 border-0 shadow-sm hover-shadow-lg transition-all'>
      <Card.Body className='p-4'>
        <div className='mb-3'>{icon}</div>
        <h3 className='h5 fw-bold mb-3'>{title}</h3>
        <Card.Text className='text-muted'>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
}

function FeatureItem({ title, description }) {
  return (
    <div className='d-flex gap-3'>
      <div className='flex-shrink-0 mt-1'>
        <BiCheck className='fs-4 text-danger' />
      </div>
      <div>
        <h3 className='h5 fw-bold mb-2'>{title}</h3>
        <p className='text-muted'>{description}</p>
      </div>
    </div>
  );
}
